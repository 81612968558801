import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import BillingRouter from './internal/BillingRouter'
import BusinessRouter from './internal/BusinessRouter'
import {DashboardRouter} from './internal/DashboardRouter'
import ManagementsRouter from './internal/ManagementsRouter'
import {ProviderRouter} from './internal/ProviderRouter'
import {ReportsRouter} from './internal/ReportsRouter'
import {SupportRouter} from './internal/SupportRouter'
import {SolicitationRouter} from './internal/SolicitationRouter'
import {GatewaysRouter} from './internal/GatewaysRouter'
import {PrincipalRouter} from './internal/PrincipalRouter'

export enum PrivateRoutesEnum {
    ShowConsult = '/consultas/:id',
}

export function PrivateRoutes() {
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

    const ConsultPage = lazy(() => import('../pages/consults/ListConsults'))

    const ConsultProfilePage = lazy(() => import('../pages/consults/ProfileConsult'))

    const CouponsPage = lazy(() => import('../pages/consults/coupons/DatatableCoupons'))
    const CouponsProfile = lazy(() => import('../pages/consults/coupons/ProfileCoupon'))
    const CouponRegister = lazy(() => import('../pages/consults/coupons/RegisterCoupon'))
    const TermsAndConditions = lazy(
        () => import('../pages/terms-and-conditions/TermsAndConditions')
    )

    const AuditPage = lazy(() => import('../pages/audit/ListAudit'))

    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                <Route path='/account' component={AccountPage} />
                <Route path='/termos-e-condicoes' component={TermsAndConditions} />
                <Route path={PrivateRoutesEnum.ShowConsult} component={ConsultProfilePage} />
                <Route path='/consultas' component={ConsultPage} />

                <Route path='/cupons/cadastro' component={CouponRegister} />
                <Route path='/cupons/:id' component={CouponsProfile} />
                <Route path='/cupons' component={CouponsPage} />

                <Route path='/principal' component={PrincipalRouter} />

                <Route path='/solicitacoes' component={SolicitationRouter} />

                <Route path='/prestadores' component={ProviderRouter} />

                <Route path='/gestao' component={ManagementsRouter} />

                <Route path='/financeiro' component={BillingRouter} />

                <Route path='/auditoria' component={AuditPage} />

                <Route path='/suporte' component={SupportRouter} />

                <Route path='/empresarial' component={BusinessRouter} />
                <Route path='/gateway' component={GatewaysRouter} />

                <Route path='/dashboard' component={DashboardRouter} />
                <Route path='/relatorios' component={ReportsRouter} />

                <Redirect from='/autenticacao' to='/' />
                <Redirect exact from='/' to='/dashboard/administrativo' />
                <Redirect to='error/404' />
            </Switch>
        </Suspense>
    )
}
