import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export enum GatewayRoutesEnum {
    DatatablePdvPending = '/gateway/pdvs/pendentes',
    DatatablePdvApproved = '/gateway/pdvs/aprovados',
    ShowPdv = '/gateway/pdvs/:id',
    StorePdv = '/gateway/pdvs/cadastro',
    DatatableOrders = '/gateway/financeiro/pedidos',
    ShowOrder = '/gateway/financeiro/pedidos/:id',
    DatatableStatements = '/gateway/financeiro/extrato',
    DatatableClosures = '/gateway/financeiro/fechamento',
    DatatablePendingClosures = '/gateway/financeiro/repasse-automatico',
    ShowClosure = '/gateway/financeiro/fechamento/:id',
    DatatableUsers = '/gateway/acessos/usuarios',
    StoreUser = '/gateway/acessos/usuarios/cadastro',
    ShowUser = '/gateway/acessos/usuarios/:cpf',
    DatatablePrivileges = '/gateway/acessos/perfis',
    StorePrivilege = '/gateway/acessos/perfis/cadastrar',
    UpdatePrivilege = '/gateway/acessos/perfis/editar/:id',
}

export function GatewaysRouter() {
    const DatatablePdvPending = lazy(() => import('../../pages/gateways/pdvs/DatatablePdvPending'))
    const DatatablePdvApproved = lazy(
        () => import('../../pages/gateways/pdvs/DatatablePdvApproved')
    )
    const ShowPdv = lazy(() => import('../../pages/gateways/pdvs/ShowPdv'))
    const StorePdv = lazy(() => import('../../pages/gateways/pdvs/StorePdv'))

    const DatatableOrders = lazy(
        () => import('../../pages/gateways/financial/orders/DatatableOrders')
    )
    const ShowOrder = lazy(() => import('../../pages/gateways/financial/orders/ShowOrder'))
    const DatatableStatements = lazy(
        () => import('../../pages/gateways/financial/statements/DatatableStatements')
    )
    const DatatableClosures = lazy(
        () => import('../../pages/gateways/financial/closures/DatatableClosures')
    )
    const DatatablePendingClosures = lazy(
        () => import('../../pages/gateways/financial/closures/DatatablePendingClosures')
    )
    const ShowClosure = lazy(() => import('../../pages/gateways/financial/closures/ShowClosure'))

    const DatatableUsers = lazy(() => import('../../pages/gateways/access/users/DatatableUsers'))
    const StoreUser = lazy(() => import('../../pages/gateways/access/users/StoreUser'))
    const ShowUser = lazy(() => import('../../pages/gateways/access/users/ShowUser'))

    const DatatablePrivileges = lazy(
        () => import('../../pages/gateways/access/privileges/DatatablePrivileges')
    )
    const StorePrivilege = lazy(
        () => import('../../pages/gateways/access/privileges/StorePrivilege')
    )
    const UpdatePrivilege = lazy(
        () => import('../../pages/gateways/access/privileges/UpdatePrivilege')
    )

    return (
        <Switch>
            <Route path={GatewayRoutesEnum.StorePdv} component={StorePdv} />
            <Route path={GatewayRoutesEnum.DatatablePdvPending} component={DatatablePdvPending} />
            <Route path={GatewayRoutesEnum.DatatablePdvApproved} component={DatatablePdvApproved} />
            <Route path={GatewayRoutesEnum.ShowPdv} component={ShowPdv} />

            <Route path={GatewayRoutesEnum.ShowOrder} component={ShowOrder} />
            <Route path={GatewayRoutesEnum.DatatableOrders} component={DatatableOrders} />

            <Route path={GatewayRoutesEnum.DatatableStatements} component={DatatableStatements} />

            <Route path={GatewayRoutesEnum.ShowClosure} component={ShowClosure} />
            <Route path={GatewayRoutesEnum.DatatableClosures} component={DatatableClosures} />
            <Route
                path={GatewayRoutesEnum.DatatablePendingClosures}
                component={DatatablePendingClosures}
            />

            <Route path={GatewayRoutesEnum.StoreUser} component={StoreUser} />
            <Route path={GatewayRoutesEnum.ShowUser} component={ShowUser} />
            <Route path={GatewayRoutesEnum.DatatableUsers} component={DatatableUsers} />

            <Route path={GatewayRoutesEnum.UpdatePrivilege} component={UpdatePrivilege} />
            <Route path={GatewayRoutesEnum.StorePrivilege} component={StorePrivilege} />
            <Route path={GatewayRoutesEnum.DatatablePrivileges} component={DatatablePrivileges} />
        </Switch>
    )
}
